@import url("https://fonts.googleapis.com/css2?family=Pacifico&display=swap");

body::-webkit-scrollbar {
  width: 1em;
  background: transparent;
}
body::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background: transparent;
}
body::-webkit-scrollbar-thumb {
  background: linear-gradient(0, #79c23c, #bb4c31);
  outline: 1px solid transparent;
  border-radius: 12px;
}
body {
  margin: 0;
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scrollbar-color: #79c23c #bb4c31;
  scrollbar-width: thin;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.ant-typography {
  font-family: "Pacifico", cursive;
}
