@import url(https://fonts.googleapis.com/css2?family=Pacifico&display=swap);
body::-webkit-scrollbar{width:1em;background:transparent}body::-webkit-scrollbar-track{-webkit-box-shadow:inset 0 0 6px rgba(0,0,0,0.3);background:transparent}body::-webkit-scrollbar-thumb{background:linear-gradient(0, #79c23c, #bb4c31);outline:1px solid transparent;border-radius:12px}body{margin:0;height:100%;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale;scrollbar-color:#79c23c #bb4c31;scrollbar-width:thin}code{font-family:source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace}.ant-typography{font-family:"Pacifico", cursive}

.App_App__3Hhdo{text-align:center}@media (prefers-reduced-motion: no-preference){.App_App-logo__1Sjr1{-webkit-animation:App_App-logo-spin__8aGHE infinite 20s linear;animation:App_App-logo-spin__8aGHE infinite 20s linear}}.App_App-header__1YBIh{min-height:100vh;display:flex;flex-direction:column;align-items:center;justify-content:center;font-size:calc(10px + 2vmin);color:white}@-webkit-keyframes App_App-logo-spin__8aGHE{0%,100%{transform:rotate(-20deg)}50%{transform:rotate(20deg)}}@keyframes App_App-logo-spin__8aGHE{0%,100%{transform:rotate(-20deg)}50%{transform:rotate(20deg)}}

.CookieConsent_CookieBanner__1kvpd{background:rgba(0,21,41,0.8);color:#cecece;display:block;position:fixed;bottom:0;left:0;width:100%;z-index:1;text-align:center;height:auto;padding-top:16px}

.Dashboard_Dashboard__3U9rW{max-width:1024px;margin:0 auto}.Dashboard_CardContainer__m0i6g{white-space:nowrap;overflow:auto;padding:0}.Dashboard_Card__dlO1D{display:inline-block;margin-left:8px}.Dashboard_Card__dlO1D .Dashboard_ant-card-body__1TRNU{padding:18px 4px}.Dashboard_Card__dlO1D .Dashboard_ant-list-item__1D4Sj:hover{box-shadow:1px 1px 6px;transition:box-shadow 0.1s ease-in}.Dashboard_QuickSearchContainer__B2xVG{text-align:left;max-height:400px;height:100%;padding:0 16px;margin:8px 0 16px;overflow:auto}.Dashboard_QuickSearchContainer__B2xVG>div{overflow:auto;max-height:350px}.Dashboard_HighlightedContainer__1FGS7{border:1px dashed #001529;border-radius:4px;padding:0 16px;box-shadow:1px 1px 10px #7d7d7d;overflow:auto;max-height:400px}

.HighlightedPlant_PlantImage__3Le2R{width:100%;height:180px;background-position:center center;background-size:auto 100%;background-repeat:no-repeat;margin-top:16px}.HighlightedPlant_Title__3YF0e{margin-top:-40px;text-shadow:1px 1px 12px whitesmoke}.HighlightedPlant_SeasonBox__35RUb{position:absolute;right:calc(100% - 21px);transition:all 0.3s ease-in;min-width:100px}.HighlightedPlant_SeasonBox__35RUb:hover{right:calc(100% - 100px);cursor:default;background:#a9c2e7;color:black}.HighlightedPlant_SeasonBox__35RUb ul{list-style:none;margin:0;padding:0;text-align:right}.HighlightedPlant_SeasonBox__35RUb ul .HighlightedPlant_cyan__1mtyw{background-color:cyan}.HighlightedPlant_SeasonBox__35RUb ul .HighlightedPlant_green__1PZAa{background-color:green}.HighlightedPlant_SeasonBox__35RUb ul .HighlightedPlant_red__1t6tS{background-color:red}.HighlightedPlant_SeasonBox__35RUb ul .HighlightedPlant_gold__ZH8Lv{background-color:gold}

.QuickSearchTable_SelectedItem__3gLQm{background:#3f51b545;border-left:3px solid rgba(255,255,255,0.3)}.ant-list .ant-list-items .ant-list-item{transition:all 0.3s ease-out}.ant-list .ant-list-items .ant-list-item:hover{background:#3f51b545}

