.SelectedItem {
  background: #3f51b545;
  border-left: 3px solid rgba(255, 255, 255, 0.3);
}

:global {
  .ant-list {
    .ant-list-items {
      .ant-list-item {
        transition: all 0.3s ease-out;
        &:hover {
          background: #3f51b545;
        }
      }
    }
  }
}
