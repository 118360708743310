.PlantImage {
  width: 100%;
  height: 180px;
  background-position: center center;
  background-size: auto 100%;
  background-repeat: no-repeat;
  margin-top: 16px;
}

.Title {
  margin-top: -40px;
  text-shadow: 1px 1px 12px whitesmoke;
}

.SeasonBox {
  position: absolute;

  right: calc(100% - 21px);
  transition: all 0.3s ease-in;
  min-width: 100px;

  &:hover {
    right: calc(100% - 100px);
    cursor: default;
    background: #a9c2e7;
    color: black;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    text-align: right;

    .cyan {
      background-color: cyan;
    }
    .green {
      background-color: green;
    }
    .red {
      background-color: red;
    }
    .gold {
      background-color: gold;
    }
  }
}
