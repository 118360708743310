.CookieBanner {
  background: rgba(0, 21, 41, 0.8);
  color: #cecece;
  display: block;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 1;
  text-align: center;
  height: auto;
  padding-top: 16px;
}
